import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { matClose } from '@ng-icons/material-icons/baseline';
import _ from 'lodash-es';

export interface SearchSelect {
  label: string | number;
  value: string | number;
  checked?: boolean;
  key?: number;
}

@Component({
  selector: 'base-search-select, [base-search-select]',
  standalone: true,
  imports: [ReactiveFormsModule, NgIcon],
  templateUrl: './search-select.component.html',
  styleUrl: './search-select.component.css',
  providers: [
    provideIcons({
      matClose,
    }),
  ],
})
export class SearchSelectComponent implements OnChanges {
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('select') selectEl!: ElementRef;

  @Input() caret: boolean = true;
  @Input() options!: SearchSelect[];
  @Input() value: string | number = '';
  @Input() placeholder: string = 'Select';
  @Input() searchClass: string = '';
  @Input() isClear: boolean = false;
  @Input() required: boolean = false;
  @Output() updateEvent = new EventEmitter<SearchSelect[]>();

  selectActive: boolean = false;
  optionList: SearchSelect[] = [];
  searchTerm = new FormControl('');

  constructor(private renderer: Renderer2) {
    this.renderer.listen('document', 'mousedown', (event) => {
      if (!this.selectEl.nativeElement.contains(event.target)) this.selectActive = false;
    });
  }

  ngOnChanges(): void {
    //this.searchTerm.setValue(this.value.toString());
    this.optionList = this.options.map((o, index) => {
      return {
        ...o,
        label: o.label.toString(),
        key: index,
        checked: false,
      };
    });
  }

  setSearchTerm(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.searchTerm.patchValue(value);
    this.selectActive = true;
  }

  filteredOptions() {
    const searchText = this.searchTerm.value?.trim().toLowerCase();
    if (!searchText) return this.optionList;
    return this.optionList.filter((x) => {
      return (
        x.label.toString().toLowerCase().includes(searchText) ||
        x.value.toString().includes(searchText)
      );
    });
  }

  handleCheck(event: Event, key?: string | number) {
    this.searchTerm.patchValue('');
    this.searchInput.nativeElement.value = '';

    if (key === undefined) return;

    this.optionList.forEach((x, index) => {
      this.optionList[index].checked = false;
    });

    this.optionList[Number(key)].checked = true;
    const chosenLabel = this.optionList[Number(key)].label;
    this.value = chosenLabel;

    //this.searchTerm.patchValue(this.optionList[Number(key)].value.toString());
    this.emitChange();
  }

  emitChange() {
    const selectedItem = this.optionList.filter((x) => x.checked) ?? [];
    const value = _.map(selectedItem, 'value');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.updateEvent.emit(<any>value?.[0]);
    this.selectActive = false;
  }

  clear() {
    this.value = '';
    this.emitChange();
  }
}
