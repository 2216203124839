<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus -->
<div class="search-select" [class]="{ active: selectActive }" #select (click)="selectActive = true">
  @if (caret) {
    <i class="gg-chevron-down"></i>
  }

  <input
    type="text"
    #searchInput
    [placeholder]="value !== '' ? value : placeholder"
    [class.active]="value !== ''"
    [class]="searchClass"
    (keyup)="setSearchTerm($event)"
    [required]="required"
  />

  <ul class="select-options">
    @for (item of filteredOptions(); track $index) {
      <li
        [tabindex]="$index"
        class="select-option"
        [class]="{ active: item.checked }"
        (click)="handleCheck($event, item.key)"
        (keyup)="handleCheck($event, item.key)"
      >
        {{ item.label }}
      </li>
    } @empty {
      <li class="no-record-found tw-text-gray-400 tw-text-xs tw-p-3">No records found</li>
    }
  </ul>
  @if (value && isClear) {
    <i class="clear-selection">
      <ng-icon name="matClose" (click)="selectActive = false; clear()" />
    </i>
  }
</div>
